<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          :src="appLogoImage"
          alt="logo"
          class="sosty-logo-header"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
            class="sosty-login-image"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Bienvenido a CarbonoVivo! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Por favor, entra con tu email y contraseña
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
              <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    name="login-email"
                    placeholder="john@example.com"
                  />
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Contraseña</label>
                  <b-link :to="{name:'forgot-password'}">
                    <small>Olvido su Contraseña?</small>
                  </b-link>
                </div>
                <b-input-group
                    class="input-group-merge"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
              </b-form-group>

              <!-- submit buttons -->
              <b-button v-if="!processing" type="submit" variant="primary" block @click="login">
                Login
              </b-button>

              <b-button v-if="processing" type="submit" variant="primary" disabled block>
                 <b-spinner small label="Cargando..." />
              </b-button>

            </b-form>
          </validation-observer>
          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              o usa
            </div>
          </div> -->

          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center">
            <!-- <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button> -->
            <!-- <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button> -->
            <!-- <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button> -->
            <!-- <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button> -->
          </div>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser, getAbilitiesBasedOnUserType } from '@/auth/utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { $themeConfig } from '@themeConfig'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,    
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const { appLogoImage } = $themeConfig.app
    return {
      appLogoImage,
    }
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/carbonovivo.png'),
      processing: false,
      redirectToProjectCode: false,
    }
  },
  created() {
    if(this.$route != null && this.$route.query != null && this.$route.query.redirectToProjectCode != null) {
      this.redirectToProjectCode = this.$route.query.redirectToProjectCode;
    } 
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      return this.sideImg
    },
  },
  methods: {
     login() {
        this.processing = true;
        useJwt.login({
          email: this.userEmail,
          password: this.password,
        })
        .then(response => {
            this.processing = false;      
            useJwt.setToken(response.data.accessToken)
            var abilities = getAbilitiesBasedOnUserType(response.data.user.userType);
            response.data.user.ability = abilities;
            localStorage.setItem('userData', JSON.stringify(response.data.user));
            this.$ability.update(abilities);            
            if(this.redirectToProjectCode){
              this.$router.replace({path: 'public-project-details?projectCode=' + this.redirectToProjectCode});              
            }else{
              this.$router.replace(getHomeRouteForLoggedInUser(response.data.user.userType));
            }            
        })
        .catch(error => {
          this.processing = false;
            if(error.response){
              this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            } else {
              this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
        })
    },

    goToRegisterWithRedirection() {
      if(this.redirectToProjectCode){
        this.$router.replace({path: 'register?redirectToProjectCode=' + this.redirectToProjectCode});
      }else{
        this.$router.replace({path: 'register'});
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
